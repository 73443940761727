<template>
    <div>
        <steps :with-buttons="false">
            <!-- 
              -- disabled for now as all community / property
              -- information is managed via the auth app
              --  
            <step title="Property Info">
                <property-form :property="property"></property-form>
            </step>
              --
            -->
            <step title="Basic Setup">
                <basic-setup-form :property="property"></basic-setup-form>
            </step>
            <step title="Contact Info">
                <contact-info-form :property="property"></contact-info-form>
            </step>
            <step title="Amenities">
                <amenities-form :customerCommunityAmenities="customerCommunityAmenities"></amenities-form>
            </step>
            <step title="Templates">
                <templates-form :property="property"></templates-form>
            </step>
        </steps>
    </div>
</template>

<script>

    import Auth from "@/mixins/Auth";
    import {mapActions, mapGetters} from "vuex";
    import Step from "@/components/ui/steps/Step";
    import Steps from "@/components/ui/steps/Steps";
    import ModalNavigation from '@/mixins/ModalNavigation';
    import AmenitiesForm from "@/components/cms/websites/AmenitiesForm";
    import TemplatesForm from "@/components/cms/websites/TemplatesForm";
    import BasicSetupForm from "@/components/cms/websites/BasicSetupForm";
    import ContactInfoForm from "@/components/cms/websites/ContactInfoForm";
    import {prepoluatedCommunityAmenities, prepoluatedApartmentAmenities} from "@/utils/CustomerPrepolulatedAmenities";

    export default {
        components: { BasicSetupForm, Steps, Step, ContactInfoForm, AmenitiesForm, TemplatesForm },
        mixins: [ModalNavigation, Auth],
        data: () => {
            return {
                property: {},
                customerCommunityAmenities: {
                    Community: [],
                    Apartment: []
                }
            }
        },
        computed: {
            ...mapGetters({
                getPropertyById: 'properties/getPropertyById',
                customerAmenities: 'amenities/getCustomerAmenities',
            }),
        },
        methods: {
            ...mapActions({
                loadAmenitiesIconsInStore: 'amenities/loadAmenitiesIcons',
                loadCommunityAmenitiesTypesInStore: 'amenities/loadCommunityAmenitiesTypes',
                loadCustomerAmenitiesInStore: 'amenities/loadCustomerAmenities',
                loadGlobalTemplatesInStore: 'templates/loadGlobalTemplates',
                clearCustomerCommunityAmenitiesInStore: 'amenities/clearCustomerCommunityAmenities',
                addCustomerAmenityToStore: 'amenities/addCustomerAmenity',
            }),
            fetchAmenitiesIcons() {
                return this.$cmsDataProvider.get('amenitiesIcons')
                    .then(response => {
                        this.loadAmenitiesIconsInStore(response);
                    })
                    .catch((err) => this.notifyError(err));
            },
            fetchCommunityAmenitiesTypes() {
                return this.$cmsDataProvider.get('communityAmenitiesTypes')
                    .then(response => {
                        this.loadCommunityAmenitiesTypesInStore(response);
                    })
                    .catch((err) => this.notifyError(err));
            },
            fetchCustomerAmenities() {
                return this.$cmsDataProvider.get('customerAmenities');
            },
            fetchGlobalTemplates() {
                return this.$cmsDataProvider.get('globalTemplates')
                    .then(response => {
                        this.loadGlobalTemplatesInStore(response);
                    })
                    .catch((err) => this.notifyError(err));
            },
            storeCustomerAmenities() {
                let payload = { amenities: [...prepoluatedCommunityAmenities, ...prepoluatedApartmentAmenities] };

                return this.$cmsDataProvider.create('multipleCustomerAmenities', { data: payload });
            },
            preloadCustomerCommunityAmenities() {
                this.customerAmenities.forEach( item => {
                    this.customerCommunityAmenities[item.community_amenities_type_name].push({
                        amenity: item.community_amenities_name ,
                        type: item.community_amenities_type_name,
                        icon: item.amenity_icons_name
                    });
                })

                // sort by alphabetical order
                this.customerCommunityAmenities.Community.sort((a, b) => (a.amenity > b.amenity) ? 1 : -1);
                this.customerCommunityAmenities.Apartment.sort((a, b) => (a.amenity > b.amenity) ? 1 : -1);
            }
        },
        mounted() {
            this.fetchGlobalTemplates();
            this.fetchAmenitiesIcons();
            this.fetchCommunityAmenitiesTypes();
            this.clearCustomerCommunityAmenitiesInStore();

            this.fetchCustomerAmenities().then( (response) => {
                if(!response.length) {
                    this.storeCustomerAmenities().then( (items) => {
                        this.loadCustomerAmenitiesInStore(items);
                        this.preloadCustomerCommunityAmenities();
                    });
                } else {
                    this.loadCustomerAmenitiesInStore(response);
                    this.preloadCustomerCommunityAmenities();
                }
            });
        },
        watch: {
            '$route.query.propertyId': {
                handler: function() {
                    this.propertyId = this.$route.query.propertyId;
                    this.property = this.getPropertyById(this.propertyId);
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>