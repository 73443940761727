<template>
    <div v-show="isEnabled && isActive">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
        },
        data: () => {
            return {
                isEnabled: false,
                isActive: false,
                index: null
            }
        }
    }
</script>