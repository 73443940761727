<template>
    <div class="flex justify-between flex-1 h-full ">
        <div class="w-1/3 bg-blue-50 mr-8 p-8">
            <h3 class="text-black font-frank font-600 text-xl">basic setup info</h3>
            <p class="text-sm font-inter text-blue-800 mt-4 font-500">
                Enter the basic information about the website, including it’s url and api information.
            </p>
        </div>
        <div class="w-2/3">
            <div class="bg-gray-100 p-4 mb-6">
                <div class="form-row">
                    <div class="form-col">
                        <label for="production_url">production url*</label>
                        <input type="text" class="form-input" id="production_url" v-model="setup.production_url" />
                        <span v-if="hasError('setup.production_url')" class="form-error">{{ showError('setup.production_url') }}</span>
                        <span class="form-hint" v-else>This is the url (www…) for your main application</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="resident_portal_url">resident portal url</label>
                    <input type="text" class="form-input" id="resident_portal_url" v-model="setup.resident_portal_url" />
                    <span v-if="hasError('setup.resident_portal_url')" class="form-error">{{ showError('setup.resident_portal_url') }}</span>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="apply_online_url">apply online url</label>
                    <input type="text" class="form-input" id="apply_online_url" v-model="setup.apply_online_url" />
                    <span v-if="hasError('setup.apply_online_url')" class="form-error">{{ showError('setup.apply_online_url') }}</span>
                </div>
            </div>
            <modal-footer :footer="footer" :primary="next" :secondary="close"></modal-footer>
        </div>

    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import ModalNavigationMixin from "@/mixins/ModalNavigation";
    import NotifyMixin from "@/mixins/NotifyMixin";

    export default {
        mixins: [ModalNavigationMixin, NotifyMixin],
        components: { ModalFooter },
        props: {
            property: {
                required: false,
                type: Object,
                default: () => { return {} }
            }
        },
        data: () => {
            return {
                setup: {
                    production_url: null,
                    resident_portal_url: null,
                    apply_online_url: null,
                },
                footer: {
                    primaryButton: 'next',
                    secondaryButton: 'go back'
                }
            }
        },
        methods: {
            next() {
                if(this.validator.passes()) {
                    // This event is caught in the last step of creating a website: components\websites\TemplateForm@mounted()
                    this.$cmsDataProvider.get('checkWebsiteProd', {domainUrl: this.setup.production_url})
                    .then((response) => {
                    if(response.message === 'available'){
                        EventBus.emit('website-basic-setup-filled', this.setup);
                        EventBus.emit('next-step');
                    }else{
                        if(response.message === 'taken'){
                            this.notifyError('The production url: '+this.setup.production_url+' is already in use. Try another one.');
                        }
                        if(response.message === 'error'){
                            this.notifyError('There is an error checking the production url.');
                        }
                    }
                }).catch(() => {
                    this.notifyError('There was an error validating the production url: '+this.setup.production_url);
                });
                }
            },
            previous() {
                EventBus.emit('previous-step');
            }
        },
        validations: {
            'setup.production_url': 'required | url',
            'setup.resident_portal_url': 'when_present | url',
            'setup.apply_online_url': 'when_present | url',
        },
        created() {
            this.initValidator();
            this.production_url = this.property ? this.property?.websiteUrl : null
        }
    }
</script>