<template>
    <div class="flex flex-col  flex-1 h-full ">
        <div class="bg-gray-100 p-4 mb-6">
            <div class="form-row">
                <div class="form-col">
                    <label for="email">property email address*</label>
                    <text-input
                      id="email"
                      v-model="contact.email"
                    />
                    <span v-if="hasError('contact.email')" class="form-error">{{ showError('contact.email') }}</span>
                    <span class="form-hint" v-else>All Emails from contact forms will be sent to this email address.</span>
                </div>
                <div class="form-col">
                    <label for="phone">property phone number*</label>
                    <text-input
                      id="phone"
                      v-model="contact.phone"
                    />
                    <span v-if="hasError('contact.phone')" class="form-error">{{ showError('contact.phone') }}</span>
                    <span class="form-hint" v-else>This phone number will be listed on the website.</span>
                </div>
            </div>
        </div>

        <hours-of-operations @operation-hours-change="updateHours"></hours-of-operations>

        <modal-footer :footer="footer" :primary="next" :secondary="previous"></modal-footer>

    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";
    import HoursOfOperations from "@/components/operation_hours/HoursOfOperations";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import {mapActions} from "vuex";
    import TextInput from '@/components/ui/TextInput';

    export default {
        components: {ModalFooter, HoursOfOperations, TextInput },
        props: {
            property: {
                required: false,
                type: Object,
                default: () => { return {} }
            }
        },
        data: () => {
            return {
                contact: {
                    email: null,
                    phone: null,
                    hours: []
                },
                footer: {
                    primaryButton: 'next',
                    secondaryButton: 'go back'
                }
            }
        },
        methods: {
            ...mapActions({
                notifyError: 'alerts/notifyError',
            }),
            updateHours(payload) {
                this.contact.hours = payload;
            },
            validateHours() {
                let valid = true

                this.contact.hours.forEach(hour => {
                    if (! hour.description.length) {
                        valid = false
                    }
                })

                if (!valid) {
                    this.notifyError('You must specify the days for all contact hours.');
                }

                return valid
            },
            next() {
                if(this.validator.passes()) {
                    if (this.validateHours()) {
                        // This event is caught in the last step of creating a website: components\websites\TemplateForm@mounted()
                        EventBus.emit('website-contact-filled', this.contact);
                        EventBus.emit('next-step');
                    }
                }
            },
            previous() {
                EventBus.emit('previous-step');
            }
        },
        validations: {
            'contact.email': 'required | email',
            'contact.phone': 'required',
        },
        created() {
            this.initValidator();
            this.contact.email = this.property ? this.property?.email : null
            this.contact.phone = this.property ? this.property?.phone : null
        }
    }
</script>
