<template>
    <div>
        <div class="flex justify-between flex-1 h-full ">
            <div class="w-1/3 bg-blue-50 mr-8 p-8">
                <h3 class="text-black font-frank font-500 text-xl">amenities</h3>
                <p class="text-sm font-inter text-blue-800 mt-4 font-500">
                    Add amenities for both the community and the individual apartments. Each amenity will have a name and an associated icon.
                </p>
                <p class="text-sm font-inter text-blue-800 mt-4 font-500 mt-4">
                    Note: These are global amenities lists. Adding, editing or removing an amenity here will effect all properties.
                </p>
            </div>
            <div class="w-2/3 grid grid-cols-2 col-gap-4">
                <div class="bg-gray-100 py-4 px-6">
                    <div class="flex justify-between">
                        <div class="text-black font-frank font-500 text-base">community</div>
                        <amenities-picker type="Community" @amenity-picked="addCommunityAmenity" :customer-community-amenities="amenities.Community"></amenities-picker>
                    </div>
                    <amenities-list
                        group="Community"
                        :editable="false"
                        :save-when-sorted="false"
                        :customer-community-amenities="customerCommunityAmenities.Community"
                        @remove-amenity="removeAmenity"
                    ></amenities-list>
                </div>
                <div class="py-4 px-6">
                    <div class="flex justify-between">
                        <div class="text-black font-frank font-500 text-base">apartment</div>
                        <amenities-picker type="Apartment" @amenity-picked="addApartmentAmenity" :customer-community-amenities="amenities.Apartment"></amenities-picker>
                    </div>
                    <amenities-list
                        group="Apartment"
                        :editable="false"
                        :save-when-sorted="false"
                        :customer-community-amenities="customerCommunityAmenities.Apartment"
                        @remove-amenity="removeAmenity"
                    ></amenities-list>
                </div>
            </div>
        </div>
        <modal-footer :footer="footer" :primary="next" :secondary="previous"></modal-footer>
    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";
    import AmenitiesList from "./AmenitiesList";
    import AmenitiesPicker from "./AmenitiesPicker";
    import ModalFooter from "@/components/ui/modals/ModalFooter";

    export default {
        components: {AmenitiesPicker, AmenitiesList, ModalFooter },
        props: {
            customerCommunityAmenities: {
                required: true,
            },
        },
        data: () => {
            return {
                amenities: {
                    Community: [],
                    Apartment: [],
                },
                footer: {
                    primaryButton: 'next',
                    secondaryButton: 'go back'
                }
            }
        },
        methods: {
            next() {
                // This event is caught in the last step of creating a website: components\websites\TemplateForm@mounted()
                EventBus.emit('website-amenities-filled', this.amenities);

                EventBus.emit('next-step');
            },
            previous() {
                EventBus.emit('previous-step');
            },
            addCommunityAmenity(payload) {
                this.addAmenity(payload, 'Community');
            },
            addApartmentAmenity(payload) {
                this.addAmenity(payload, 'Apartment');
            },
            addAmenity(payload, type) {
                this.amenities[type].push(payload);
            },
            removeAmenity(payload) {
                this.amenities[payload.type] = this.amenities[payload.type].filter (item => {
                    return item.amenity !== payload.amenity
                })
            },
        },
        mounted() {
            this.amenities = this.customerCommunityAmenities;
        }
    }
</script>