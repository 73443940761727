<template>
    <div class="flex flex-col flex-1 h-full ">
        <div v-if="!isComplete && !isProcessing">
            <p class="mb-6">
                Select a template for your website from the options below. After you have selected your template,
                you will be given the option to customize the colors and fonts.
            </p>
            <div class="grid grid-cols-6 gap-6">
                <div class="col-span-2" v-for="(template, index) in allGlobalTemplates" :key="index">
                    <div class="col-span-2">
                        <p class="font-bold tracking-wider font-inter text-highlight-900 text-sm">{{ templateNames[index] }}</p>
                        <p class="text-xs tracking-normal mt-2 leading-tight overflow-auto mb-4">{{ templateDescription[index] }}</p>
                    </div>
                    <div class="h-64 overflow-auto col-span-2 bg-gray-100 cursor-pointer"
                    :class="{ 'border-4 border-blue-500': template.name === selectedTemplate}"
                    @click="selectedTemplate = template.name"
                    >
                        <img
                            :src="getTemplateThumbnailUrl(template.thumbnail_url)"
                            class="w-full"
                        >
                    </div>
                </div>
            </div>
            <div class=" flex items-center mt-16">
                <div><toggle-input v-model="copyFromTemplate" class="mr-4" /></div>
                <div>
                    <p class="text-blue-500 font-semibold">Populate site with Pages and Modules</p>
                    <span class="text-sm font-inter text-gray-500">
                        Want to save time creating a professional website? Keep this toggle on, and we will build the outline for you. No need to build from scratch – just add text and photos, then rearrange and edit the template as much as you want!
                    </span>
                </div>
            </div>
            <modal-footer :footer="footer" :primary="save" :secondary="previous"></modal-footer>
        </div>
        <div v-else class="text-indigo-300 text-center p-6 w-full">
            <div v-if="isProcessing" class="text-center">
                <p class="text-gray-500 mb-3 text-base">Setting up the website. Please be patient.</p>
                <icon name="gridLoader" class="h-20 w-20 inline"></icon>
            </div>
            <div v-else>
                <icon name="complete" class="h-48 w-48 inline mb-4"></icon>
                <p class="mb-4">Website setup complete!</p>
                <button
                    class="btn-primary"
                    @click="redirect('websites.show', { id: website.id })"
                >
                    Visit CMS Dashboard
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import Auth from "@/mixins/Auth";
    import EventBus from "@/utils/EventBus";
    import Icon from "@/components/ui/Icon";
    import ToggleInput from "@/components/ui/ToggleInput";
    import {mapActions, mapGetters} from "vuex";
    import ModalFooter from "@/components/ui/modals/ModalFooter";

    export default {
        mixins: [ Auth ],
        components: { Icon, ModalFooter, ToggleInput },
        props: {
            property: {
                required: true,
                type: Object
            }
        },
        data: () => {
            return {
                templateNames: [
                    'the rigor',
                    'the hirst',
                    'the urbana'
                ],
                templateDescription: [
                    "Minimalistic design and monochromatic colors use strategic white space. Images and copy purposely don't overlap for a clean and aligned look.",
                    "Elegant and stylistic details frame your property’s best features with a noticeably expensive feel and format.",
                    "Slender lines and delicate touches of personality create a cohesive user experience. Selective colors complement the site's light ambiance."
                ],
                copyFromTemplate: true,
                isComplete: false,
                isProcessing: false,
                website: {},
                integrationPartners: {},
                amenities: null,
                selectedTemplate: null,
                footer: {
                    primaryButton: 'next',
                    secondaryButton: 'go back'
                }
            }
        },
        computed: {
            ...mapGetters({
                allGlobalTemplates: 'templates/getGlobalTemplates'
            }),
        },
        methods: {
            ...mapActions({
                addOrUpdatePropertyInStore: 'properties/addOrUpdateProperty',
                addWebsiteInStore: 'websites/addWebsite',
                popAllModals: 'modals/popAllModals'
            }),

            save() {
                this.isProcessing = true;
                this.website.communities_id = this.property.communityId;
                // 2- create the website
                this.createWebsite().then( (response) => {
                    this.website.id = response.id;
                    // 3- create the website integrations partners and website templates
                    axios.all([
                        this.createIntegrationPartners(),
                        //this.createCustomerCommunityAmenities(),
                        this.createWebsiteTemplate()
                    ])
                    .then(axios.spread(() => {
                        // 4- create the website gallery and property amenities
                        axios.all([
                            this.createPropertyAmenities()
                        ])
                        .then(axios.spread(() => {
                            // 5- load website and store it in vuex
                            this.getWebsiteInfo().then( (response) => {
                                this.addWebsiteInStore(response);
                                this.isProcessing = false;
                                this.isComplete = true;
                            });

                        }));
                    }));
                })
            },
            previous() {
                EventBus.emit('previous-step');
            },
            createWebsite() {
                return this.$cmsDataProvider.create('createWebsite', this.website);
            },
            createWebsiteTemplate() {
                let params = {
                    websiteId: this.website.id,
                    data: {
                        templates_name: this.selectedTemplate,
                        is_custom: false,
                        copy_template: this.copyFromTemplate,
                    }
                };

                this.$cmsDataProvider.create('createWebsiteTemplate', params);
            },
            createIntegrationPartners() {
                let params = '';

                if(this.integrationPartners.cloudflare_api_key) {
                    params = {
                        websiteId: this.website.id,
                        data: {
                            website_integration_partner_name: 'cloudflare',
                            integration_parameter_name: 'cloudflare_api_key',
                            integration_parameter_value: this.integrationPartners.cloudflare_api_key
                        }
                    };
                    this.$cmsDataProvider.create('createWebsiteIntegrationPartner', params);
                }

                if(this.integrationPartners.recapcha_id) {
                    params = {
                        websiteId: this.website.id,
                        data: {
                            website_integration_partner_name: 'recapcha',
                            integration_parameter_name: 'recapcha_id',
                            integration_parameter_value: this.integrationPartners.recapcha_id
                        }
                    };
                    this.$cmsDataProvider.create('createWebsiteIntegrationPartner', params);
                }

                if(this.integrationPartners.nestio_id && this.integrationPartners.nestio_api_key) {
                    params = {
                        websiteId: this.website.id,
                        data: {
                            website_integration_partner_name: 'nestio',
                            integration_parameter_name: 'nestio_id',
                            integration_parameter_value: this.integrationPartners.nestio_id
                        }
                    };
                    this.$cmsDataProvider.create('createWebsiteIntegrationPartner', params);

                    params = {
                        websiteId: this.website.id,
                        data: {
                            website_integration_partner_name: 'nestio',
                            integration_parameter_name: 'nestio_api_key',
                            integration_parameter_value: this.integrationPartners.nestio_api_key
                        }
                    };
                    this.$cmsDataProvider.create('createWebsiteIntegrationPartner', params);
                }
            },
            // createCustomerCommunityAmenities() {
            //     this.amenities.Community.forEach( (item) => {
            //         this.$cmsDataProvider.create('createCustomerCommunityAmenity', {
            //             community_amenities_name: item.amenity,
            //             community_amenities_type_name : 'Community',
            //             amenity_icons_name: item.icon
            //         });
            //     })
            //
            //     this.amenities.Apartment.forEach( (item) => {
            //         this.$cmsDataProvider.create('createCustomerCommunityAmenity', {
            //             community_amenities_name: item.amenity,
            //             community_amenities_type_name : 'Apartment',
            //             amenity_icons_name: item.icon
            //         });
            //     })
            // },
            createPropertyAmenities() {
                let params = {};
                let communities = this.amenities.Community;
                let apartments = this.amenities.Apartment;
                let amenities = communities.concat(apartments);

                amenities.forEach((item) => {
                    params = {
                        communityId: this.website.communities_id,
                        data: {
                            customer_community_amenities_name: item.amenity,
                            order: 0
                        }
                    };
                    this.$cmsDataProvider.create('createCustomerCommunityAmenity', params);
                });
            },
            getWebsiteInfo() {
                return this.$cmsDataProvider.getOne('showWebsite', { id: this.website.id });
            },
            redirect(routeName, params) {
                this.$router.push( {name: routeName, params}).then( () => {});
                this.popAllModals();
            },
            getTemplateThumbnailUrl(templateName) {
                try {
                    return require('@/assets/theme/' + templateName);
                }
                catch(err) {
                    return require('@/assets/modules/NA.png');
                }
            }
        },
        mounted() {

            this.selectedTemplate = this.allGlobalTemplates?.[0]?.name ?? 'Template 1';
        
            EventBus.on('website-property-filled', (payload) => {
                this.property = {
                    id: payload.id,
                    communities_name: payload.name,
                    address: payload.physical_address.street,
                    city: payload.physical_address.city,
                    state: payload.physical_address.state,
                    zip: payload.physical_address.zip,
                    mailing_address: payload.mailing_address.street,
                    mailing_city: payload.mailing_address.city,
                    mailing_state: payload.mailing_address.state,
                    mailing_zip: payload.mailing_address.zip,
                    email: payload.email,
                    phone: payload.phone
                };
            });

            EventBus.on('website-basic-setup-filled', (payload) => {
                this.website = {
                    production_url: payload.production_url,
                    resident_portal_url: payload.resident_portal_url,
                    apply_online_url: payload.apply_online_url,
                };

                this.integrationPartners = {
                    cloudflare_api_key: payload.cloudflare_api_key,
                    recapcha_id: payload.recapcha_id,
                    nestio_id: payload.nestio_id,
                    nestio_api_key: payload.nestio_api_key,
                }
            });

            EventBus.on('website-contact-filled', (payload) => {
                this.website.contact_phone = payload.phone;
                this.website.contact_email = payload.email;
                this.website.hours = JSON.stringify(payload.hours);
            });

            EventBus.on('website-amenities-filled', (payload) => {
                this.amenities = payload;
            });
        }
    }
</script>
<style scoped>
.template-card-wrapper{
    display: grid;
    grid-template-rows: 1fr 16rem;
    grid-template-columns: auto;
}
</style>
