<template>
    <drop-menu
        direction="right"
        min-width="20rem"
        overflow="visible"
        :flex-header="true"
        :close-on-outside-click="false"
    >
        <template v-slot:header>
            <a class="text-highlight-textSmall text-xs font-frank font-500 underline block cursor-pointer">
                add amenity
            </a>
        </template>

        <template v-slot:default="{ close }"> <!-- this means use the close() method from the parent drop-menu component -->
            <div>
                <p class="hidden text-highlight-base text-xs font-bold block text-right mb-4">choose amenity</p>
                <div class="mt-4 mb-2 text-xs font-bold flex justify-between">amenity
                </div>
                <div>
                <dropdown
                    v-if="!addNew"
                    :options="filteredOptions"
                    :placeholder="'Select an existing amenity'"
                    v-model="selectedAmenity"
                ></dropdown>
                <div class="mt-2">
                    <text-input
                      id="newAmenity"
                      placeholder="Add new Amenity"
                      v-model="newAmenity"
                    />
                </div>

                <label class="mt-4 mb-2 text-xs block font-bold">icon</label>
                <icon-picker v-model="selectedIcon" :icons="icons" :direction="iconPickerDirection"></icon-picker>

                <div class="mt-8 flex items-center justify-between">
                    <button class="btn-primary btn-transparent mr-2" @click="cancel(close)">Cancel</button>
                    <button class="btn-primary btn-solid" @click="add(close)" :disabled="(!selectedAmenity || !newAmenity) && !selectedIcon">add</button>
                </div>
                </div>
            </div>
        </template>
    </drop-menu>
</template>

<script>

    import { mapGetters, mapActions } from "vuex";
    import DropMenu from "@/components/ui/DropMenu";
    import Dropdown from "@/components/ui/Dropdown";
    import IconPicker from "@/components/ui/IconPicker";
    import TextInput from '@/components/ui/TextInput';

    export default {
        components: { DropMenu, Dropdown, IconPicker, TextInput },
        props: {
            customerCommunityAmenities: {
                required: false,
                type: Array
            },
            type: {
                required: true,
                type: String
            },
            iconPickerDirection: {
                required: false,
                type: String,
                default: 'left'
            }
        },
        emits: ['amenity-picked'],
        data: () => {
            return {
                options: [],
                icons: [],
                selectedIcon: null,
                selectedAmenity: null,
                addNew: false,
                newAmenity: ''
            }
        },
        computed: {
            ...mapGetters({
                allAmenitiesIcons: 'amenities/getIcons',
                customerAmenities: 'amenities/getCustomerAmenities'
            }),
            filteredOptions() {
                let options = [];
                this.customerAmenities.forEach( item => {
                    if(item.community_amenities_type_name === this.type && !this.customerCommunityAmenities.find( customerCommunityAmenity => customerCommunityAmenity.amenity === item.community_amenities_name))
                        options.push({ key: item.community_amenities_name, value: item.community_amenities_name});
                });
                return options;
            }
        },
        methods: {
            ...mapActions({
                addCustomerAmenityToStore: 'amenities/addCustomerAmenity',
            }),
            add(close) {
                if((this.selectedAmenity || this.newAmenity) && this.selectedIcon) {
                    let amenity = this.newAmenity != '' ? this.newAmenity : this.selectedAmenity;

                    if(this.newAmenity != '') {
                        this.$cmsDataProvider.create('createCustomerAmenity', {
                            community_amenities_name: amenity,
                            community_amenities_type_name : this.type,
                            amenity_icons_name: this.selectedIcon
                        }).then( (response) => {
                            this.addCustomerAmenityToStore(response);
                        })
                    }

                    this.$emit('amenity-picked', {
                        amenity: amenity,
                        icon: this.selectedIcon,
                        type: this.type
                    });

                    this.cancel(close);
                }
            },
            cancel(close) {
                this.selectedIcon = null;
                this.selectedAmenity = null;
                this.addNew = false;
                this.newAmenity = null;
                close();
            }
        },
        watch: {
            selectedAmenity: {
                handler: function () {
                    if(this.selectedAmenity && !this.addNew) {
                        let customerAmenity = null;

                        if(this.customerAmenities.length)
                            customerAmenity = this.customerAmenities.find( amenity => {
                                return amenity.community_amenities_name === this.selectedAmenity;
                            });

                        if(customerAmenity) {
                            this.newAmenity = ''
                            this.selectedIcon = customerAmenity.amenity_icons_name;
                        }
                    }
                    else {
                        this.selectedIcon = null;
                    }
                },
            },
            newAmenity: {
                handler: function () {
                    if (this.newAmenity != ''){
                        this.selectedAmenity = null
                    }
                }
            }
        },
        mounted() {
            this.allAmenitiesIcons.forEach(item => {
                // hide certain icons
                if(!['house', 'trash', 'socialActivities'].includes((item.name)))
                    this.icons.push({ name: item.name});
            });
        }
    }
</script>
