export const prepoluatedCommunityAmenities = [
    {
        community_amenities_name: 'Pool',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'pool',
    },
    {
        community_amenities_name: 'Multiple Pools',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'pool',
    },
    {
        community_amenities_name: 'Heated Pool',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'pool',
    },
    {
        community_amenities_name: 'Lap Pool',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'pool',
    },
    {
        community_amenities_name: 'Fitness Center',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'gym',
    },
    {
        community_amenities_name: 'Fitness Classes',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'gym',
    },
    {
        community_amenities_name: 'iFit Fitness Equipment',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'gym',
    },
    {
        community_amenities_name: 'Peloton Fitness Equipment',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'peleton',
    },
    {
        community_amenities_name: 'Fitness Center - Free Weights',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'gym',
    },
    {
        community_amenities_name: 'Sport Courts',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'basketball',
    },
    {
        community_amenities_name: 'Basketball Court',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'basketball',
    },
    {
        community_amenities_name: 'Tennis Court',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'basketball',
    },
    {
        community_amenities_name: 'Volleyball Court',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'basketball',
    },
    {
        community_amenities_name: 'Sand Volleyball Court',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'basketball',
    },
    {
        community_amenities_name: 'Business Center General',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'laptop',
    },
    {
        community_amenities_name: 'Computer Lab',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'laptop',
    },
    {
        community_amenities_name: 'Conference Room',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'laptop',
    },
    {
        community_amenities_name: 'Dog Park',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'dog2',
    },
    {
        community_amenities_name: 'Dog Washing Station',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'dog2',
    },
    {
        community_amenities_name: 'Concierge',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'user',
    },
    {
        community_amenities_name: 'Package Handling',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'bag',
    },
    {
        community_amenities_name: 'Park',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'tree',
    },
    {
        community_amenities_name: 'Pond',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'duck',
    },
    {
        community_amenities_name: 'Walking Trail',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'train',
    },
    {
        community_amenities_name: 'Laundry Facilities',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'washer',
    },
    {
        community_amenities_name: 'Parking Available',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'parking',
    },
    {
        community_amenities_name: 'Guest Parking',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'parking',
    },
    {
        community_amenities_name: 'Handicap Parking',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'parking',
    },
    {
        community_amenities_name: 'Bike Parking',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'parking',
    },
    {
        community_amenities_name: 'Reserved Parking',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'parking',
    },
    {
        community_amenities_name: 'Covered Parking',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'parking',
    },
    {
        community_amenities_name: 'Parking Garage',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'parking',
    },
    {
        community_amenities_name: 'Golf',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'basketball',
    },
    {
        community_amenities_name: 'Community Events',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'pizza',
    },
    {
        community_amenities_name: 'Social Activities',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'coffee',
    },
    {
        community_amenities_name: 'Clubhouse',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'coffee',
    },
    {
        community_amenities_name: 'Espresso Bar',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'coffee',
    },
    {
        community_amenities_name: 'Lounge Area',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'coffee',
    },
    {
        community_amenities_name: 'Fire Pits',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'fireplace',
    },
    {
        community_amenities_name: 'Outdoor Cooking',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'grill',
    },
    {
        community_amenities_name: 'Playground',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'playground',
    },
    {
        community_amenities_name: 'Lake',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'boat',
    },
    {
        community_amenities_name: 'School',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'school',
    },
    {
        community_amenities_name: 'Technology Package',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'power',
    },
    {
        community_amenities_name: 'High-Speed Internet',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'wifi',
    },
    {
        community_amenities_name: 'Video Streaming',
        community_amenities_type_name: 'Community',
        amenity_icons_name: 'play',
    },
];

export const prepoluatedApartmentAmenities = [
    {
        community_amenities_name: 'Air Conditioning',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'pool',
    },
    {
        community_amenities_name: 'Large Bathrooms',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'bath',
    },
    {
        community_amenities_name: 'Oversize Tub',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'bath',
    },
    {
        community_amenities_name: 'Separate Tub and Shower',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'shower',
    },
    {
        community_amenities_name: 'Marble Countertops',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'sink',
    },
    {
        community_amenities_name: 'Large Bedrooms',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'bed',
    },
    {
        community_amenities_name: 'Walk-in Closets',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'closet',
    },
    {
        community_amenities_name: 'Wood Floors',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'floors',
    },
    {
        community_amenities_name: 'Luxury Vinyl Plank',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'floors',
    },
    {
        community_amenities_name: 'Plush Carpeting',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'floors',
    },
    {
        community_amenities_name: 'Open Floorplans',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'floors',
    },
    {
        community_amenities_name: 'ADA Accessibility',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'floors',
    },
    {
        community_amenities_name: 'High Ceilings',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'floors',
    },
    {
        community_amenities_name: 'Patios or Balconies',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'floors',
    },
    {
        community_amenities_name: 'Stainless Steel Appliances',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'fridge',
    },
    {
        community_amenities_name: 'Energy efficient Appliances',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'fridge',
    },
    {
        community_amenities_name: 'Dishwasher',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'washer',
    },
    {
        community_amenities_name: 'Garbage Disposal',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'trash',
    },
    {
        community_amenities_name: 'Microwave',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'gas',
    },
    {
        community_amenities_name: 'Gas Range',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'gas',
    },
    {
        community_amenities_name: 'Washer and Dryer Connection',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'washer',
    },
    {
        community_amenities_name: 'Washer and Dryer',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'washer',
    },
    {
        community_amenities_name: 'Granite Countertops',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'sink',
    },
    {
        community_amenities_name: 'Quartz Countertops',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'sink',
    },
    {
        community_amenities_name: 'Stone Countertops',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'sink',
    },
    {
        community_amenities_name: 'Ceiling Fans',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'fan',
    },
    {
        community_amenities_name: 'Ceiling Fan Connections',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'fan',
    },
    {
        community_amenities_name: 'Streaming Digital Television',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'tv',
    },
    {
        community_amenities_name: 'Cable Television',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'tv',
    },
    {
        community_amenities_name: 'High Speed Internet',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'wifi',
    },
    {
        community_amenities_name: 'Custom Cabinets',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'cabinet',
    },
    {
        community_amenities_name: 'Smart Locks',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'smartLock',
    },
    {
        community_amenities_name: 'Smart Thermostat',
        community_amenities_type_name: 'Apartment',
        amenity_icons_name: 'smartThermostat',
    },
];
