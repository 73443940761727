<template>
    <ul class="mt-6">
        <draggable
            v-if="amenities"
            :list="amenities"
            item-key="order"
            :group="group"
            :sort="true"
            @change="updateAmenitiesOrder"
            handle=".amenity-item"
        >
            <template v-slot:item="{element: item}">
            <li class="amenity-item text-gray-700 text-sm my-3 flex items-center justify-center cursor-move">
                <div class="amenity-item-icon">
                    <icon :name="item.icon"></icon>
                </div>
                <span class="block truncate text-base font-400">{{ item.amenity }}</span>
                <div class="remove-amenity cursor-pointer flex items-center justify-end">
                    <modal-link
                        route="websites.amenities.edit"
                        :params="{ id: $route.params.id, amenityName: item.amenity, amenityId: item.id }"
                        v-if="editable"
                        class="flex items-center"
                    >
                        <icon name="pencil" class="w-4 h-4 text-highlight-base inline-block mr-2" />
                    </modal-link>
                    <icon name="closeCircle" @click="$emit('remove-amenity', item)" class="w-4 h-4 inline-block text-gray-600" />
                </div>
            </li>
            </template>
        </draggable>
    </ul>
</template>

<script>
    import draggable from 'vuedraggable';
    import Icon from "@/components/ui/Icon";
    import { mapGetters, mapActions} from "vuex";
    import ModalLink from "@/components/ui/modals/ModalLink";

    export default {
        components: { Icon, ModalLink, draggable },
        props: {
            customerCommunityAmenities: {
                required: true
            },
            editable: {
                type: Boolean,
                required: false,
                default: false
            },
            group: {
                type: String,
                required: true,
                default: 'Community'
            },
            saveWhenSorted: {
                type: Boolean,
                default: true,
                required: false,
            }
        },
        emits: ['remove-amenity'],
        data: () => {
            return {
                websiteId: null,
                website: null,
                amenities: null
            }
        },
        computed: {
            ...mapGetters({
                getCustomerCommunityAmenitiesByType: 'amenities/getCustomerCommunityAmenitiesByType',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                loadCustomerCommunityAmenitiesByTypeInStore: 'amenities/loadCustomerCommunityAmenitiesByType'
            }),
            updateAmenitiesOrder() {
                this.amenities.map((amenity, index) => {
                    amenity.order = index;
                    return amenity;
                });

                if(this.saveWhenSorted) {
                    this.$cmsDataProvider.create('reorderCustomerCommunityAmenities', {
                        communityId: this.website.communities_id,
                        data: {
                            amenities: this.amenities
                        }
                    }).then(() => {
                        this.loadCustomerCommunityAmenitiesByTypeInStore({type: this.group, data: this.amenities});
                    })
                }
            },          
        },
        watch: {
            customerCommunityAmenities: {
                handler: function () {
                    this.amenities = this.customerCommunityAmenities;
                },
                immediate: true
            }
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.websiteId = this.$route.params.id;

        }
    }
</script>

<style scoped>
.amenity-item{
    display:grid;
    grid-template-columns: 18px 1fr 48px;
    grid-column-gap: 15px;
}
.amenity-item-icon svg{
    width: 18px;
    height: 18px;
}

.amenity-item .remove-amenity {
    opacity: 1;
}
.amenity-item:hover .remove-amenity {
    opacity: 1;
}
</style>
