<template>
    <div>
        <drop-menu min-width="32rem" :direction="direction">
            <template v-slot:header>
                <div class="cursor-pointer text-sm">
                    <div class="border border-gray-300 py-3 pl-4 pr-10 inline-block relative w-full">
                        <div class="flex items-center" v-if="!icon">
                            select icon
                        </div>
                        <icon v-if="icon" :name="icon" class="h-6 w-6 mr-1 inline"></icon>
                        <icon name="chevronDown" class="chevy absolute h-4 w-4 inline"></icon>
                    </div>
                </div>
            </template>
            <template v-slot:default="{ close }"> <!-- this means use the close() method from the parent drop-menu component -->
                <div class="icons-wrapper">
                    <div v-for="icon in icons" :key="icon.name" class="icon cursor-pointer hover:bg-blue-200" @click="selectIcon(icon, close)">
                        <icon :name="icon.name" class="w-4 h-4 text-gray-700" :title="icon.name"></icon>
                    </div>
                </div>
                <div class="mt-8 text-right">
                    <button class="btn-primary btn-transparent mr-2" @click="close">Cancel</button>
                </div>
            </template>
        </drop-menu>
    </div>
</template>

<script>
    import Icon from "@/components/ui/Icon";
    import DropMenu from "@/components/ui/DropMenu";

    export default {
        components: { DropMenu, Icon },
        props: {
            value: {
                required: true
            },
            icons: {
                required: true,
                type: Array,
                default: () => { return [] }
            },
            direction: {
                type: String,
                required: false,
                default: 'left'
            }
        },
        emits: ['input'],
        data: () => {
            return {
                icon: null
            }
        },
        methods: {
            selectIcon(icon, close) {
                this.icon = icon.name;
                this.$emit('input', this.icon);
                close();
            }
        },
        watch: {
            value: {
                handler: function () {
                    this.icon = this.value
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>
.icons-wrapper{
    @apply flex flex-wrap;
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
    grid-gap: 15px;
    max-height: 200px;
    overflow: auto;
}
.icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon svg{
    width: 30px;
    height: 30px;
}
.icon:hover{
    @apply bg-blue-200;
}

.chevy{
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
</style>
