<template>
    <div>
        <!-- steps indicator -->
        <div class="steps-wrapper w-full mb-8 flex justify-between flex-row">
            <span
                v-for="(step, index) in steps"
                :key="index"
                class="text-gray-400 font-frank text-xs py-2 inline-block font-500 border-t-2 border-gray-200 text-center"
                v-html="step.title.toLowerCase()"
                :class="getStepClasses(step)"
                @click="!disableStepsNavigation ? goBackToStep(index) : {}"
            >
            </span>
        </div>

        <!-- step content -->
        <slot></slot>

        <!-- previous/next buttons -->
        <div class="modal-footer flex justify-between mt-4" v-if="withButtons">
            <button class="btn-primary" @click="previous" v-if="currentStep">Previous</button>
            <button class="btn-primary ml-auto" @click="next" v-if="currentStep < steps.length -1">next</button>
            <slot name="final-step-action" v-if="currentStep === steps.length - 1">
            </slot>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";

    export default {
        props: {
            withButtons: {
                type: Boolean,
                required: false,
                default: true
            },
            resetScrollId: {
                type: String,
                required: false,
                default: 'modal-body-container'
            },
            disableStepsNavigation: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data: () => {
            return {
                steps: [],
                classes: [],
                currentStep: 0
            }
        },
        methods: {
            setStepsIndexes: function() {
                this.steps.map( (step, index) => {
                    step.index = index;
                });
            },
            goBackToStep(index) {
                if (index < this.currentStep) {
                    this.goToStep(index)
                }
                return
            },
            goToStep(index) {
                if(!this.steps[index])
                    return;

                this.steps[index].isEnabled = true;

                this.steps.map( (step) => {
                    step.isActive = false;
                });
                this.steps[index].isActive = true;
                this.currentStep = index
            },
            getStepClasses(step) {
                let classes = [ 'w-1/'+this.steps.length ];

                if(step.isEnabled) {
                    classes.push('text-highlight-base');
                    classes.push('border-highlight-base');
                    if(!this.disableStepsNavigation)
                        classes.push('cursor-pointer')
                }

                return classes.join(' ');
            },
            next() {
                this.currentStep++;
                this.goToStep(this.currentStep);

                // reset the scroll
                let modalContainer = document.getElementById(this.resetScrollId);
                modalContainer.scrollTop = 0;
            },
            previous() {
                this.steps[this.currentStep].isEnabled = false;
                this.currentStep--;
                this.goToStep(this.currentStep);

                // reset the scroll
                let modalContainer = document.getElementById(this.resetScrollId);
                modalContainer.scrollTop = 0;
            }
        },
        mounted() {
          this.steps = this.$children;
            this.setStepsIndexes();
            this.goToStep(this.currentStep);

            EventBus.on( 'next-step', () => {
                this.next();
            });

            EventBus.on( 'previous-step', () => {
                this.previous();
            })
        }
    }
</script>
